<template>

<app-content :loading="is.loading" :fullheight="true">

	<app-content-head title="Tickets" :is-app="true" app-action="scan" v-on:action="is.scanning = true" />

	<app-content-body :no-padding="true" class="event" v-if="!is.loading">

		<com-scan :allowClose="true" :allowManual="true" v-on:scanned="onScanDetected" v-on:close="is.scanning = false" :failed="is.failed" :checking="is.checking" v-if="is.scanning" />

		<com-event v-if="!ticket" :event="event" />
		<com-tickets v-if="!ticket" :tickets="tickets" v-on:click="onTicketClick" />

		<com-ticket :item="ticket" :used="used" :limit="limit" :event="event" v-if="ticket" v-on:cancel="ticket = false" v-on:admit="load" />

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	components: {
		'com-scan': () => import('./common/Scan'),
		'com-event': () => import('./ticketsevent/Event'),
		'com-ticket': () => import('./ticketsevent/Ticket'),
		'com-tickets': () => import('./ticketsevent/Tickets')
	},

	data: function() {
	
		return {
			is: {
				failed: false,
				loading: true,
				checking: false,
				scanning: false
			},
			ticket: false,
			used: 0,
			limit: 0,
			event: false,
			tickets: []
		}

	},

	created: function() {

		this.$pubsub.$on('scan', this.onScanDetected)

		this.load()

	},

	beforeDestroy: function() {

		this.$pubsub.$off('scan', this.onScanDetected)

	},

	methods: {

		onTicketClick: function(ticket) {

			Vue.set(this, 'ticket', ticket)
			this.used = 0
			this.limit = 1

		},

		onScanDetected: function(code) {

			this.is.checking = true
			this.is.failed = false

			this.$api.get(['tickets/event',  this.$route.params.id, 'check', code]).then(function(json) {
				
				Vue.set(this, 'ticket', json.ticket)
				this.used = json.used
				this.limit = json.limit
				
				this.is.checking = false
				this.is.scanning = false

			}.bind(this), function(json) {

				if (json.ticket) {

					Vue.set(this, 'ticket', json.ticket)
					this.used = json.used
					this.limit = json.limit

					this.is.scanning = false

				} else {

					this.is.failed = true

				}

				this.is.checking = false

			}.bind(this))

		},

		load: function() {

			this.is.loading = true
			this.ticket = false

			this.$api.get(['tickets/event', this.$route.params.id], this.$route.query).then(function(json) {

				Vue.set(this, 'event', json.event)
				Vue.set(this, 'tickets', json.tickets)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.event {
	background-color: #fff;
	display: flex;
	flex-direction: column;
}

</style>
